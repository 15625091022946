import axios from "axios";
import { errorMessage } from "@/helper/helper";
import router from "@/router";
const state = {
  profile_info: {},
  profile_actions: [],
  roles: [],
};

const getters = {
  getProfileInfo: (state) => {
    return { ...state.profile_info };
  },
  getRolesList: (state) => state.roles,
};

const mutations = {
  setProfileInfo(state, info) {
    info.is_super = !!info.is_super;
    state.profile_info = info;
    if (!info.role) {
      errorMessage("You have no assigned role!");
      router.push({ name: "page-forbidden" });
      return;
    }
    const role = localStorage.getItem("role");
    if (role && +role !== info.role) {
      // if handled new role - set new and reload page to rebuild frontend with new role conditions
      localStorage.setItem("role", info.role);
      window.location.reload();
    }
  },

  setRoles(state, payload) {
    state.roles = payload;
  },
};

const actions = {
  setProfileInfo({ commit }, info) {
    commit("setProfileInfo", info);
  },
  async fetchProfileInfo({ commit }) {
    try {
      const {
        data: {
          data,
          meta: { roles },
        },
      } = await axios.post("/profile");
      commit("setProfileInfo", data);
      commit("setRoles", roles);
    } catch (e) {}
  },
};

export default {
  state,
  getters,
  mutations,
  actions,
};
