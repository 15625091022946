<template>
  <div id="login-page">
    <div class="logo"></div>

    <el-form
      class="login-form"
      :rules="rules"
      ref="loginForm"
      :model="loginForm"
    >
      <el-form-item prop="email">
        <el-input v-model="loginForm.email" placeholder="Email"></el-input>
      </el-form-item>
      <el-form-item>
        <el-input
          type="password"
          v-model="loginForm.password"
          placeholder="Password"
        ></el-input>
      </el-form-item>
      <el-form-item class="loginBlock">
        <el-button type="primary" @click="login('loginForm')" :loading="loading"
          >Login</el-button
        >
      </el-form-item>
      <el-form-item class="forgotPassword">
        <a href="#" @click="openRestorePasswordDialog()">Forgot password?</a>
      </el-form-item>
    </el-form>

    <el-dialog
      id="restorePasswordDialog"
      class="midi-dialog"
      :visible.sync="restorePasswordDialog"
    >
      <div class="text-center">
        Enter your e-mail, the instructions how to reset your password <br />
        will be sent on your e-mail
      </div>
      <el-form
        class="restore-password-form"
        :rules="rules"
        ref="restorePasswordForm"
        :model="restorePasswordForm"
      >
        <el-form-item prop="email">
          <el-input
            v-model="restorePasswordForm.email"
            placeholder="Email"
          ></el-input>
        </el-form-item>
      </el-form>
      <span slot="footer" class="dialog-footer">
        <div class="restore-password-form-btns">
          <el-button
            class="btn-outline-primary"
            @click="restorePasswordDialog = false"
            >Cancel</el-button
          >
          <el-button
            type="primary"
            @click="restorePassword('restorePasswordForm')"
            >Send</el-button
          >
        </div>
      </span>
    </el-dialog>
  </div>
</template>

<script>
export default {
  data() {
    // валидаторы для форм
    const validators = {
      email: (rule, value, callback) => {
        if (value === "") {
          callback(new Error("Please input the email"));
        } else {
          callback();
        }
      },
      pass: (rule, value, callback) => {
        if (value === "") {
          callback(new Error("Please input the password"));
        } else if (value.length < 6) {
          callback(new Error("Password length must be at 6 symbols at least"));
        } else if (!/(?=.*\d)(?=.*[a-z])/i.test(value)) {
          callback(new Error("Password must contain numbers and letters"));
        } else {
          callback();
        }
      },
    };

    return {
      // данные авторизации
      loginForm: {
        email: "",
        password: "",
      },

      // правила валидации форм
      rules: {
        email: [
          { validator: validators.email, trigger: "blur, change" },
          {
            type: "email",
            message: "Please input correct email address",
            trigger: "blur,change",
          },
        ],
        password: [
          {
            type: "password",
            validator: validators.pass,
            trigger: "blur,change",
          },
        ],
      },

      // диалог восстановления пароля
      restorePasswordDialog: false,
      restorePasswordForm: {
        email: "",
      },

      loading: false,
    };
  },
  methods: {
    // авторизация пользователя
    login(formName) {
      this.$refs[formName].validate((valid) => {
        if (valid) {
          this.loading = true;
          this.$http
            .post("/auth/login", this.loginForm)
            .then((response) => {
              let data_response =
                response && response.data && response.data.data;

              if (data_response && response.headers.authorization) {
                this.$store.dispatch("signin", {
                  ...data_response,
                  ...{
                    token: response.headers.authorization,
                  },
                });

                this.$router.push({ name: "user-list" });
              }
            })
            .catch((error) => {
              let message =
                error.response.data && error.response.data.message
                  ? error.response.data.message
                  : "Incorrect login or password";
              this.$alert(message, "Error", {
                confirmButtonText: "OK",
                type: "error",
                center: true,
              });
            })
            .finally(() => {
              this.loading = false;
            });
        } else {
          return false;
        }
      });
    },

    // открытие формы восстановления пароля
    openRestorePasswordDialog() {
      this.restorePasswordForm.email = "";

      this.restorePasswordDialog = true;
    },
    // восстановление пароля
    restorePassword(formName) {
      this.$refs[formName].validate((valid) => {
        if (valid) {
          this.$http
            .post("/auth/restore", this.restorePasswordForm)
            .then((response) => {
              this.$alert(
                "Instructions about how to change password are sent to your email",
                "Success",
                { confirmButtonText: "OK", type: "success", center: true }
              );
            })
            .catch((error) => {
              console.error(error);
              let message =
                error.response.data && error.response.data.message
                  ? error.response.data.message
                  : "Email is not a valid";
              this.$alert(message, "Error", {
                confirmButtonText: "OK",
                type: "error",
                center: true,
              });
            });

          this.restorePasswordDialog = false;
        } else {
          console.log("error submit!!");
          return false;
        }
      });
    },
  },
};
</script>
