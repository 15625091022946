import { Message, Notification } from "element-ui";
export const downloadFile = (response) => {
  try {
    const type = response.headers["content-type"];
    const url = window.URL.createObjectURL(new Blob([response.data], { type }));
    const link = document.createElement("a");
    link.href = url;
    const dispositionHeader = response.headers["content-disposition"];
    const fullName = dispositionHeader
      ? dispositionHeader.split("filename=")[1].slice(1, -1).trim()
      : "reporting.xls";
    link.setAttribute("download", fullName);
    document.body.appendChild(link);
    // This is necessary as link.click() does not work on the latest firefox
    link.dispatchEvent(
      new MouseEvent("click", {
        bubbles: true,
        cancelable: true,
        view: window,
      })
    );
    link.remove();
    window.URL.revokeObjectURL(url);
  } catch (e) {
    errorMessage("Error occurred in downloadFile method");
  }
};

export const deepCopy = (element) => {
  return JSON.parse(JSON.stringify(element));
};

export function errorMessage(message = "Error") {
  Message.error({
    showClose: true,
    message: message || "error",
    type: "error",
    duration: 4000,
  });
}
export function successMessage(message = "Success") {
  Notification.success({
    showClose: true,
    message: message || "Success",
    type: "success",
    duration: 4000,
  });
}
export function warningMessage(message = "Warning") {
  Notification.warning({
    showClose: true,
    message: message || "Warning",
    type: "success",
    duration: 4000,
  });
}
export function sortHandler(value, state, callback) {
  // clean other sorting
  for (let val in state) if (val !== value) state[val] = null;

  // clean on the third click
  if (state[value] === "asc" && Object.keys(state).length > 1) {
    state[value] = null;
    callback();
    return;
  }

  state[value] === "desc" ? (state[value] = "asc") : (state[value] = "desc");
  if (callback) callback();
}
