const stringify = require("qs-stringify");
function authMiddleware(Vue, Axios, router, store) {
  Axios.defaults.baseURL = process.env.API_HOST;

  // Добавление токена авторизации при каждом запросе к back-у
  Axios.interceptors.request.use(
    function (axios_config) {
      if (!axios_config.headers.common.hasOwnProperty("Authorization")) {
        axios_config.headers.common["Authorization"] = localStorage.getItem(
          "token"
        );
      }

      axios_config.paramsSerializer = (params) => stringify(params);

      return axios_config;
    },
    function (error) {
      return Promise.reject(error);
    }
  );

  // Обработка 401 Unauthorized для корректного разлогинивания юзера для каждого ответа back-а
  Axios.interceptors.response.use(
    function (response) {
      return response;
    },
    function (error) {
      if (error.response.status === 401) {
        store.dispatch("signout");
        router.push({ path: "/" });
      } else {
        return Promise.reject(error);
      }
    }
  );
}

export default authMiddleware;
