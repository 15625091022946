/*
 * Информация по авторизованному пользователю
 */

const state = {
  is_logged_in: !!localStorage.getItem("token"), // авторизирован ли пользователь

  self_info: {
    // ниформация по авторизированному пользователю:
    id: localStorage.getItem("uid"), // - id аккаунта
    name: localStorage.getItem("name"), // - имя
    email: localStorage.getItem("email"), // - email
    role: localStorage.getItem("role"), // -role
  },

  restore_hash: null, // хеш, полученный в ответ на ссылку восстановления пароля, используемый для установки пароля в профайле пользователя
};

const getters = {
  isLoggedIn: (state) => state.is_logged_in,

  isSuperUser: (state) => state.self_info.is_super,
  getRoleID: (state) => state.self_info.role,
  getRole(state) {
    return {
      isSuperAdmin: +state.self_info.role === 1,
      isAdmin: +state.self_info.role === 2,
      isSupport: +state.self_info.role === 3,
      isCommercial: +state.self_info.role === 4,
      isFinancial: +state.self_info.role === 5,
    };
  },
  getSelfInfo: (state) => state.self_info,
  getSelfId: (state) => state.self_info.id,
  getSelfName: (state) => state.self_info.name,
  getSelfEmail: (state) => state.self_info.email,

  getRestoreHash: (state) => state.restore_hash,
};

const mutations = {
  signin(state, info) {
    state.is_logged_in = true;

    state.self_info = info;
  },
  signout(state) {
    state.isLoggedIn = false;

    state.self_info = {};
  },
  restore_hash(state, hash) {
    state.restore_hash = hash;
  },
};

const actions = {
  signin({ commit }, credentials) {
    localStorage.setItem("token", credentials.token);
    localStorage.setItem("uid", credentials.id);
    localStorage.setItem("name", credentials.name);
    localStorage.setItem("email", credentials.email);
    localStorage.setItem("role", credentials.role);

    if (credentials.is_super) {
      localStorage.setItem("is_super", credentials.is_super);
    }

    commit("signin", credentials);
  },
  signout({ commit }) {
    localStorage.removeItem("token");
    localStorage.removeItem("uid");
    localStorage.removeItem("name");
    localStorage.removeItem("email");
    localStorage.removeItem("is_super");
    localStorage.removeItem("role");
    commit("signout");
  },
  restore({ commit }, hash) {
    commit("restore_hash", hash);
  },
};

export default {
  state,
  getters,
  mutations,
  actions,
};
