import "es6-promise/auto";

import Vue from "vue";
import router from "./router";
import store from "./store";
import "./assets/styles/main.scss";
import "vue-json-viewer/style.css";
import Vuelidate from "vuelidate";
import VueClipboard from "vue-clipboard2";
//notifications
import {
  errorMessage,
  successMessage,
  warningMessage,
  sortHandler,
} from "@/helper";
import { DATA_NOT_FOUND } from "@/constants";
import dateFilter from "./filters/date.filter.js";
// Import Axios
import Axios from "axios";
import authMiddleware from "./authMiddleware";
import VueTextareaAutosize from "vue-textarea-autosize";
// Import ElementUI
import ElementUI from "element-ui";
import locale from "element-ui/lib/locale/lang/en";
import moment from "moment";

Vue.prototype.$errorMessage = errorMessage;
Vue.prototype.$successMessage = successMessage;
Vue.prototype.$warningMessage = warningMessage;
Vue.prototype.$sortHandler = sortHandler;
Vue.prototype.$previousPage = null;
Vue.prototype.$dataNotFound = DATA_NOT_FOUND;
Vue.prototype.$http = Axios;
// Hide startup message in console
Vue.config.productionTip = false;
//clipboard copy plugin

Vue.filter("dateFilter", dateFilter);

Vue.use(VueClipboard);
Vue.use(Vuelidate);
Vue.use(authMiddleware, Axios, router, store);
Vue.use(VueTextareaAutosize);
Vue.use(ElementUI, { locale });

// Фильтр для отображеия и перевода времени в UTC пользователя

moment.updateLocale("en", {
  week: {
    dow: 1,
  },
});

let utc_offset = moment().utcOffset();
Vue.filter("date_utc", function (value) {
  let date = value || "";

  if (value) {
    let date_moment = moment(value);
    if (date_moment.isValid()) {
      date = date_moment.add(utc_offset, "minutes").format("YYYY-MM-DD HH:mm");
    }
  }

  return date;
});

Vue.filter("date_utc_s", function (value) {
  let date = value || "";

  if (value) {
    let date_moment = moment(value);
    if (date_moment.isValid()) {
      date = date_moment
        .add(utc_offset, "minutes")
        .format("YYYY-MM-DD HH:mm:ss");
    }
  }

  return date;
});

if (Array.prototype.equals)
  console.warn(
    "Overriding existing Array.prototype.equals. Possible causes: New API defines the method, there's a framework conflict or you've got double inclusions in your code."
  );
// attach the .equals method to Array's prototype to call it on any array
Array.prototype.equals = function (array) {
  // if the other array is a falsy value, return
  if (!array) return false;

  // compare lengths - can save a lot of time
  if (this.length != array.length) return false;

  for (var i = 0, l = this.length; i < l; i++) {
    // Check if we have nested arrays
    if (this[i] instanceof Array && array[i] instanceof Array) {
      // recurse into the nested arrays
      if (!this[i].equals(array[i])) return false;
    } else if (this[i] != array[i]) {
      // Warning - two different object instances will never be equal: {x:20} != {x:20}
      return false;
    }
  }
  return true;
};
// Import MainApp
import App from "./App.vue";

const root = new Vue({
  router,
  store,
  render: (h) => h(App),
});

document.addEventListener("DOMContentLoaded", () => {
  root.$mount("#app");
});
