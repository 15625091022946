import Vue from "vue";
import Vuex from "vuex";

/* Импортируем модули для хранилища постранично */
import auth from "./modules/auth";
import profile from "./modules/profile";
import dictonary from "./modules/dictonary";
import loader from "./modules/loader";
import sms_report from "./modules/sms-report";
import analytics from "./modules/analytics";

Vue.use(Vuex);

/* Main store */
export default new Vuex.Store({
  modules: {
    auth, // информация по авторизованному пользователю
    profile, // информация по профайлу админа,
    dictonary, // countries && statuses && networks
    loader, //loader state
    sms_report,
    analytics,
  },
  state: {
    isDev: process.env.APP_ENV === "develop",
  },
  getters: {
    isDev: (state) => state.isDev,
  },
  mutations: {},
  actions: {},
});
