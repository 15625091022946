<template>
  <el-popover
    placement="top"
    width="200"
    trigger="click"
    :content="text"
    popper-class="help-tip-popover"
  >
    <span class="help-tip-popover__content" slot="default">
      {{ text }}
      <slot></slot>
    </span>
    <help-icon slot="reference" :width="width" :height="height" />
  </el-popover>
</template>

<script>
import HelpIcon from "./icons/help-icon.vue";
export default {
  name: "help-tip",
  components: { HelpIcon },
  props: {
    width: {
      type: Number,
      default: 18,
    },
    height: {
      type: Number,
      default: 18,
    },
    text: {
      type: String,
    },
  },
};
</script>

<style scoped lang="scss"></style>
