<template>
  <svg
    xmlns="http://www.w3.org/2000/svg"
    viewBox="0 0 24 24"
    fill="none"
    stroke="currentColor"
    stroke-width="2"
    stroke-linecap="round"
    stroke-linejoin="round"
    class="feather feather-help-circle help-icon"
    :width="width"
    :height="height"
  >
    <circle cx="12" cy="12" r="10"></circle>
    <path d="M9.09 9a3 3 0 0 1 5.83 1c0 2-3 3-3 3"></path>
    <line x1="12" y1="17" x2="12" y2="17"></line>
  </svg>
</template>

<script>
export default {
  name: "help-icon",
  props: ["width", "height"],
};
</script>

<style scoped>
.help-icon {
  cursor: pointer;
  display: inline-block;
}
</style>
